<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Note Types'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <!--  Filter Icon -->
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="valueListsStore.generalSections"
                    item-text="value"
                    item-value="id"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <note-topic-entry />
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="noteTopicsStore.noteTopics.data && noteTopicsStore.noteTopics.data.length > 0"
      :showTotal="true"
      :currentPage="noteTopicsStore.noteTopics.current_page"
      :lastPage="noteTopicsStore.noteTopics.last_page"
      :total="noteTopicsStore.noteTopics.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="noteTopicsStore.noteTopics.data && noteTopicsStore.noteTopics.data.length > 0"
        :list-data="noteTopicsStore.noteTopics.data"
        :title="'Note Topics'"
        :section="11"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import NoteTopicEntry from "@/components/NoteTopicEntry";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "noteTopicsStore/getFilterField",
  mutationType: "noteTopicsStore/updateFilterField"
});

export default {
  name: "NoteTopics",
  components: {
    TitleBar,
    NoteTopicEntry,
    Pagination,
    ListBasic
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      noteTopicsStore: state => state.noteTopicsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields(["page", "d_name", "f_type"])
  },
  data() {
    return {
      filterMenu: false
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      /* Grab filters */
      const filters = this.$store.getters["noteTopicsStore/getFilters"];
      /* Store authorized user in store */
      await this.$store.dispatch("noteTopicsStore/fetch", filters);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.page = 1;
      this.d_name = "";
      this.f_type = "";
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.d_name || this.f_type) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.$store.dispatch("noteTopicsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
